import React, {Suspense} from 'react'
import { SpinLoading } from 'antd-mobile'
import { Outlet } from 'react-router-dom'

const AppIndexPage: React.FC = () => {
    return <Suspense fallback={
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
        }}>
            <SpinLoading color='#f00' style={{ '--size': '2rem' }} />
        </div>
    }>
        <div className='container' id='App-container'>
            <Outlet />
        </div>
    </Suspense>
}

export default AppIndexPage